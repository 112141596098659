.upnext-v1_0 {
  @import "../shared/_defaults";
  @include display-header;
  @include origin-flag-simple;

  .display-header {
    width: 50%;
    left: auto;
    right: 0;
  }

  .origin-flag {
    top: .5em;
    right: .5em;
  }

  .posts {
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: rgba($black, .5);
    overflow: hidden;

    .post {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      font-size: 3vmin;
    }
  }

  .next-posts {
    position: absolute;
    overflow: hidden;
    left: 50%;
    top: 0;
    width: 200%;
    height: 100%;

    .post {
      height: 16.666666vw;
      width: 16.666666vw;
      display: inline-block;

      &.grid {
        background-color: lighten($prod-dark-blue, 5%);
        bottom: 20%;

        &.has-media {
          .message,
          .post-time {
            display: none;
          }
        }

        .meta-author {
          position: absolute;
          bottom: 1em;
        }

        .origin-flag {
          bottom: 1em;
          top: auto;
        }

        .media-module {
          height: 100%;
          width: 100%;
          background-size: cover;
        }
      }
    }
  }

  .media-module {
    position: relative;
    width: 50%;
    height: 100%;
    float: left;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .blurred-media-module {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
  }

  .media {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .text-module {
    position: absolute;
    width: 100%;
    height: 100%;
    float: right;
    padding: 1em;
    bottom: 0;
    overflow: hidden;
    color: $white;
  }

  .has-media .text-module {
    width: 100%;
    height: 100%;
  }

  .portrait-post {
    .media-module {
      float: none;
      width: 100%;
      height: 100%;
    }

    .text-module {
      width: 100%;
      height: 100%;
    }

    &.has-media .text-module {
      float: none;
      width: 100%;
      height: 40%;
    }
  }

  .meta-author {
    position: relative;
    font-size: .8em;
    padding-left: 2.75em;
    padding-right: 1em;
    margin-bottom: .5em;
  }

  .no-avatar {
    .meta-author {
      padding-left: 0;
    }
    .avatar {
      display: none;
    }
  }

  .avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
    width: 2.25em;
    height: 2.25em;
    border-radius: 100%;
    box-shadow: 0 0 4px rgba($black, .5);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user-img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  .author,
  .handle {
    @include ellipsis;
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 2.25;
  }

  .twitter,
  .tiktok {
    .author,
    .handle {
      line-height: 1.3;
    }

    .handle {
      font-size: .8em;
    }
  }

  .post-time {
    display: none;
    font-size: .8em;
    margin-bottom: .5em;
    @include ellipsis;
  }

  .timestamp {
    font-size: .8em;
  }
}

.post-grid-item {
  transition: transform 1s;
  position: absolute;
}
